import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
// import './style.css';
import System from "./SystemFunction";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: null,
            point: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }

    }

    redeem = (e) => {
        e.preventDefault();
        this.checklogin();
        //////console.log('tag', e.target.href);
        if (!this.state.loginstate) {
            var title = 'Not login!'
            var text = ' คุณไม่ได้ทำการ Login'
            var object = { title, text }
        }
    }


    checklogin() {
        var token = localStorage.getItem('auth_token');
       if (token != null) {
            var decoded = jwt_decode(token);
            this.setState({
                user_id: decoded.message.playerid,
                playerid: decoded.message.member_id,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }


    async componentDidMount() {
        try {
            var token = localStorage.getItem('auth_token');
            const search = window.location.href;
            const params = new URLSearchParams(search);
            this.checklogin();
            await instance.post("/api/v1/tabgamelist", {
                state: "2",
                System: Systems,
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.setState({
                            categorylist: res.data.message
                        });
                    }
                    else {
                    }
                });
        }
        catch (ex) {
           // console.log(ex);
        }

    }

    render() {

        return (
            <section className="x-index-top-container">
                <div className="-index-inner-wrapper" style={{ backgroundColor: "black" }}>
                <div className="x-special-menu-entry-wrapper js-special-menu-entry-wrapper">
                        <div className="container">
                            <div className="x-special-menu-entry -main-container">
                                <ul className="nav -nav">
                                    <li className="nav-item">
                                        <button
                                            className="nav-link -menu-social-share animated fadeInUp"
                                            data-toggle="modal"
                                            data-target="#loginModal"
                                            data-dismiss="modal"
                                            data-animatable="fadeInUp"
                                            data-delay={50}
                                        >
                                            <img
                                                alt="Website name Image"
                                                className="-bg"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                            />
                                            <img
                                                alt="Website name Image"
                                                className="-text-img img-fluid"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-social-share.png?v=1"
                                            />
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className="nav-link -menu-bind-social animated fadeInUp"
                                            data-toggle="modal"
                                            data-target="#loginModal"
                                            data-dismiss="modal"
                                            data-animatable="fadeInUp"
                                            data-delay={100}
                                        >
                                            <img
                                                alt="Website name Image"
                                                className="-bg"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                            />
                                            <img
                                                alt="Website name Image"
                                                className="-text-img img-fluid"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-bind-social.png?v=1"
                                            />
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                          href="/Affiliate"
                                            className="nav-link -menu-invitation animated fadeInUp"
                                            data-animatable="fadeInUp"
                                            data-delay={150}
                                        >
                                            <img
                                                alt="Website name Image"
                                                className="-bg"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                            />
                                            <img
                                                alt="Website name Image"
                                                className="-text-img img-fluid"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-invitation.png?v=1"
                                            />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                           href="#"
                                            className="nav-link -menu-birth-date animated fadeInUp"
                                            target="_blank"
                                            rel="nofollow noopener"
                                            data-animatable="fadeInUp"
                                            data-delay={200}
                                        >
                                            <img
                                                alt="Website name Image"
                                                className="-bg"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                            />
                                            <img
                                                alt="Website name Image"
                                                className="-text-img img-fluid"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-happy-birth-day.png?v=1"
                                            />
                                        </a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a
                                            href="#"
                                            className="nav-link -menu-ranking animated fadeInUp"
                                            data-animatable="fadeInUp"
                                            data-delay={250}
                                        >
                                            <img
                                                alt="Website name Image"
                                                className="-bg"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                            />
                                            <img
                                                alt="Website name Image"
                                                className="-text-img img-fluid"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-ranking.png?v=1"
                                            />
                                        </a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a
                                            href="#"
                                            className="nav-link -menu-movie animated fadeInUp"
                                            target="_blank"
                                            rel="nofollow noopener"
                                            data-animatable="fadeInUp"
                                            data-delay={300}
                                        >
                                            <img
                                                alt="Website name Image"
                                                className="-bg"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                            />
                                            <img
                                                alt="Website name Image"
                                                className="-text-img img-fluid"
                                                src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-movie.png?v=1"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
                    <h1 className="-heading-title">Ae sexy  Casino คาสิโนออนไลน์ บาคาร่า ไฮโล เสือมังกร เว็บตรง Ae sexy 
                    </h1>
                    <div className="-banner-wrapper">
                        <div data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:true,&quot;slidesToShow&quot;:1,&quot;centerMode&quot;:true,&quot;infinite&quot;:true,&quot;autoplay&quot;:true,&quot;autoplaySpeed&quot;:4000,&quot;pauseOnHover&quot;:false,&quot;focusOnSelect&quot;:true,&quot;variableWidth&quot;:true,&quot;responsive&quot;:{&quot;sm&quot;:{&quot;fade&quot;:true,&quot;variableWidth&quot;:false}}}" className="x-banner-slide-wrapper -single">
                            <div className="-slide-inner-wrapper -slick-item">
                                <a href="/promotions" className="-link-wrapper">
                                    <img src="https://ae-sexy.bet/media/cache/strip/202206/block/ca71c3e3866365104315e7b17105cd64.jpg" alt="" className="img-fluid -slick-item -item-1" width={1200} height={590} />
                                </a>
                            </div>
                            <div className="-slide-inner-wrapper -slick-item">
                                <a href="/promotions" className="-link-wrapper">
                                    <img src="https://ae-sexy.bet/media/cache/strip/202206/block/264d59e38683bd3837e7d5b1e6939f05.jpg" alt="" className="img-fluid -slick-item -item-2" width={1200} height={590} />
                                </a>
                            </div>
                            <div className="-slide-inner-wrapper -slick-item">
                                <a href="/promotions" className="-link-wrapper">
                                    <img src="https://ae-sexy.bet/media/cache/strip/202206/block/9dd7f1df795254b4578bbed0f0e32d73.jpg" alt="" className="img-fluid -slick-item -item-3" width={1200} height={590} />
                                </a>
                            </div>
                            <div className="-slide-inner-wrapper -slick-item">
                                <a href="/promotions" className="-link-wrapper">
                                    <img src="https://ae-sexy.bet/media/cache/strip/202206/block/9dd7f1df795254b4578bbed0f0e32d73.jpg" alt="" className="img-fluid -slick-item -item-4" width={1200} height={590} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="d-lg-none">
                        <div className="x-feed-news-header">
                            <div className="-feed-news-inner-wrapper" data-animatable="fadeInUp" data-delat={200}>
                                <div className="-icon-container">
                                    <i className="fas fa-volume-up" />
                                </div>
                                <div className="-track">
                                    <div className="-track-item -duration-normal-content">
                                       Ae sexyคาสิโนออนไลน์ สล็อตออนไลน์ ที่ให้คุณสนุกได้ทุกรูปแบบ
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-games-provider-wrapper" data-animatable="fadeInModal">
                        <NavMenu />
                        <div className="-games-index-page lazyloaded" >
                            <h2 className="-heading-sub-title">Ae sexy  Casino คาสิโนออนไลน์เต็มรูปแบบ ตื่นตาตื่นใจไปกับทุกการเดิมพัน</h2>
                            <div className="x-category-provider js-game-scroll-container js-game-container ">
                                <div className="-games-list-container container">
                                    <nav className="nav-menu" id="navbarProvider">
                                        <ul className="nav nav-pills">
                                            {this.state.categorylist.map((listitem, i) => (
                                                <li key={i} id={listitem.gameid} className="nav-item -game-casino-macro-container">
                                                    <a href={"/listgameall/casino/" + listitem.partner}
                                                        className="nav-link js-account-approve-aware"
                                                        rel="nofollow noopener">
                                                        <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                                                            <div className="-inner-wrapper">
                                                                <picture>
                                                                    <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="dream-gaming" width={364} height={231} />
                                                                </picture>
                                                                <div className="-overlay">
                                                                    <div className="-overlay-inner">
                                                                        <div className="-wrapper-container">
                                                                            <button href={"/listgameall/casino/" + listitem.partner} className="-btn -btn-play js-account-approve-aware"  >
                                                                                <i className="fas fa-play" />
                                                                                <span className="-text-btn">เข้าเล่น</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-title">{listitem.description}</div>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );

    }
}
export default App;
